import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { PENDING, SUCCEEDED, FAILED } from '../constants';
import axios from 'axios';
import config from '@hulu/env-config';

export const fetchAccountsThunk = createAsyncThunk('user/accounts', async (_, { rejectWithValue }) => {
  try {
    const response = await axios.get(`${config.REACT_APP_EXAMPLE_API_URL}/user/accounts`);
    return response.data;
  } catch (e) {
    return rejectWithValue(e.response);
  }
});

const AccountsListSlice = createSlice({
  name: 'accounts',
  initialState: {
    accounts: [],
    AccountsListSliceStatus: null,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAccountsThunk.pending, (state) => {
      state.AccountsListSliceStatus = PENDING;
    });
    builder.addCase(fetchAccountsThunk.fulfilled, (state, action) => {
      state.AccountsListSliceStatus = SUCCEEDED;
      state.accounts = action.payload.accounts;
    });
    builder.addCase(fetchAccountsThunk.rejected, (state) => {
      state.AccountsListSliceStatus = FAILED;
    });
  },
});

export default AccountsListSlice.reducer;
