import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { PENDING, SUCCEEDED, FAILED } from '../constants';
import axios from 'axios';
import config from '@hulu/env-config';

export const fetchRolesThunk = createAsyncThunk('user/roles', async (_, { rejectWithValue }) => {
  try {
    const response = await axios.get(`${config.REACT_APP_EXAMPLE_API_URL}/user/roles`);
    return response.data;
  } catch (e) {
    return rejectWithValue(e.response);
  }
});

const RolesListSlice = createSlice({
  name: 'roles',
  initialState: {
    roles: [],
    RolesListSliceStatus: null,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRolesThunk.pending, (state) => {
      state.RolesListSliceStatus = PENDING;
    });
    builder.addCase(fetchRolesThunk.fulfilled, (state, action) => {
      state.RolesListSliceStatus = SUCCEEDED;
      state.roles = action.payload.roles.map((x) => {
        if (x.account.shortName === 'CP1') {
          x.account.fullName = 'Common Platform 1';
        } else if (x.account.shortName === 'CP2') {
          x.account.fullName = 'Common Platform 2';
        }

        return x;
      });
    });
    builder.addCase(fetchRolesThunk.rejected, (state) => {
      state.RolesListSliceStatus = FAILED;
    });
  },
});

export default RolesListSlice.reducer;
