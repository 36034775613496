/**
 * This file is required for 'npm run sitemap' script
 * to build a sitemap.xml file in a './app/build' folder.
 * If the project does not require a sitemap.xml then route configuration
 * can be moved to any files safely, 'sitemap' script can also be removed from root package.json file.
 * Otherwise, running `npm run sitemap` will throw an error and a message will be shown
 * to add the file mannualy.
 */
import React from 'react';
import { Routes, Route } from '@hulu/react-router-dom';

import PageNotFound from './components/PageNotFound/PageNotFound';
import Cloud from './pages/cloud';
import BookmarkSplash from './pages/BookmarkSplash';

import Authentication from './components/Authentication';
import UserDetails from './pages/cloud/User';

export default (
  <Routes>
    <Authentication checkInterval={30e3}>
      <Route path="/" element={<Cloud />} />
    </Authentication>

    <Authentication checkInterval={30e3}>
      <Route path="/user_details" element={<UserDetails />} />
    </Authentication>

    <Authentication checkInterval={5e3}>
      <Route path="/bookmark" element={<BookmarkSplash />} />
    </Authentication>

    <Route path="*" element={<PageNotFound />} />
  </Routes>
);
