import React from 'react';

function SoxIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" x="0" y="0" viewBox="-1 -0.2 359 351">
      <path
        fill="#FFF"
        d="M322.2 207.2c-12.7-9-28-11-42.8-13-28.3-3.7-50.6-6.6-53.8-52.9-4.7-67.3-6.3-82.8-23.3-95.1-.6-4.7-4-31.6-4-31.6C197.2 5.5 188.8-1 179.7.2L128 6.7c-4.4.5-8.3 2.8-11.1 6.3-1.8 2.4-3 5.1-3.3 8-7.2-.4-14.2 3.9-16.7 11 0 0-8.9 25.1-10.6 29.8C76 66.4 67.7 81 58.5 110.4l-5.2 17c-10.1 33.4-18.2 59.8-33.3 76.1-13 13.9-20 29.1-20 43 0 4.2.6 8.3 1.9 12.1 3.9 11.6 15.5 26.7 49.1 32.8 18.4 3.2 39.8 16.4 60.4 29.2 14.2 8.8 29.4 18.2 43.1 23.9 8.8 3.7 23.9 10.2 66-.4 2.2-.5 6.8-2.4 8.3-9.7.3-1.5.5-3.3.5-5.2 0-14.2-9.9-38.9-36.7-61.8 19.1 1.9 40.6 6.5 56.4 12 26.2 9.2 75.4 15.5 103.7-5 2.3-1.5 4.9-4.9 5.1-11.1 0-12.9-10.8-38.6-35.6-56.1"
      ></path>
      <path
        fill="#C62033"
        d="M318.6 212.3c-33.9-24.1-93.8 6.9-99.3-70.6-4.9-70.4-6.7-81.2-22.9-92L192 15.3c-.7-5.6-5.9-9.7-11.5-8.9l-51.7 6.5c-5 .6-8.7 4.7-9 9.5 1.8.7 5.1 1.8 9.1 3.2l-.4-3.3c-.1-.9.5-1.7 1.4-1.8.9-.1 1.7.5 1.8 1.4l.6 4.9c2.2.8 4.6 1.6 7 2.5l-1-7.7c-.1-1.2.7-2.2 1.8-2.3 1.1-.1 2.2.7 2.3 1.8l1.2 9.8c2.1.7 4.1 1.5 6.1 2.2l-1.5-12.2c-.2-1.4.8-2.7 2.2-2.8 1.4-.2 2.7.8 2.9 2.2l1.8 14.7c2 .7 3.9 1.4 5.5 2l-2.2-17.3c-.2-1.4.8-2.7 2.2-2.8 1.4-.2 2.7.8 2.8 2.2l2.5 19.9c.7.3 1.2.4 1.2.4 1.9.7 3.6 1.7 5 2.9l-3-24.3c-.1-1.1.7-2.2 1.8-2.3 1.1-.1 2.2.7 2.3 1.8l4 32.3c.6 1.7.9 3.5.9 5.3 0 1.8-.3 3.7-1 5.6 0 0-9 25.4-10.7 30 2 4 2.9 8.1 2.9 12.4 0 12.7-7.5 26.4-14.4 39-2.9 5.3-5.7 10.4-7.8 15.1-10.6 25.9-15.7 41.9-15.7 52.8 0 5.8 1.4 10.1 4.3 13.8.1-6.9 1.4-12.2 2.1-14.8 6.8 2.2 27 8.5 37.1 10.7 0 0-.8 20.8 1.7 35.7 1.2.9 2.2 1.8 2.9 2.4 1.3.9 2.6 1.8 3.9 2.8 22.8 1.3 49.9 7 67.4 13.1 27.2 9.5 73.1 14 98.1-4.3 7.3-4.5-1.6-36.7-30.3-57.1M186.3 53.6c-.9.1-1.7-.5-1.8-1.4L180 16c-.1-.9.5-1.7 1.4-1.8.9-.1 1.7.5 1.8 1.4l4.5 36.2c.1.8-.5 1.7-1.4 1.8m156.1 209.2c-8.7 5.8-20.4 8.7-32.8 9.6-2.7.1-4.4-.9-5-3l-9-24.4c-.9-2-1-3.9 1.8-5.3 0 0 18.4-8 20.5-9.1 2.1-1.1 4.6-3.1 7.1-.6 12.3 13 17.4 27.2 17.4 32.7.1-.1 0 0 0 .1"
      ></path>
      <path
        fill="#C62033"
        d="M176.8 263.2c-2.5-2-17.1-14.6-37.8-28.6-20.7-14-15.9-34.9 2.7-80.3 9.1-20.5 29.9-46.1 18.1-63.4l11.6-32.7c1.9-5.4-.9-11.3-6.3-13.2L116 27.6c-5.4-1.9-11.3.9-13.2 6.3L91.3 66.7c-9 2-17.2 15.2-26.7 45.5-12.9 41.4-21.2 75.3-39.9 95.5C-2 236.4-5 274.9 52.1 285.2c32.8 5.7 71.5 39.6 104.3 53.3 7.9 3.3 20.7 10 62.6-.5 9.1-1.8 5.6-41.6-42.2-74.8m-128 12c-17.8-4.1-28.9-11.4-32.3-21.5-3.4-10.3 1.1-23.8 12.1-36.9 12.5 4.8 23 10.6 30.8 15.9-5.5 11.7-9.3 26.4-10.6 42.5M162.5 52.5c.8.3 1.3 1.2 1 2.1l-12.3 34.3c-.3.8-1.2 1.3-2.1 1-.8-.3-1.3-1.2-1-2.1l12.3-34.3c.3-.9 1.2-1.3 2.1-1m-9.8-3.5c1.1.4 1.7 1.6 1.3 2.7l-12 33.4c-.4 1.1-1.6 1.7-2.7 1.3-1.1-.4-1.6-1.6-1.3-2.7l12-33.5c.4-1 1.6-1.6 2.7-1.2m-9.8-3.5c1.3.5 2 1.9 1.5 3.3l-11.7 32.6c-.5 1.3-1.9 2-3.3 1.6-1.3-.5-2-1.9-1.5-3.3l11.6-32.6c.6-1.4 2.1-2.1 3.4-1.6m-42.6 26.9c-.8-.3-1.3-1.2-1-2.1L111.5 36c.3-.8 1.2-1.3 2.1-1 .8.3 1.3 1.2 1 2.1l-12.3 34.3c-.3.9-1.2 1.3-2 1m9.8 3.5c-1.1-.4-1.7-1.6-1.3-2.7l11.9-33.4c.4-1.1 1.6-1.7 2.7-1.3 1.1.4 1.6 1.6 1.3 2.7l-12 33.5c-.4 1-1.6 1.6-2.6 1.2m9.7 3.5c-1.3-.5-2-1.9-1.5-3.3l11.6-32.6c.5-1.3 1.9-2 3.3-1.5 1.3.5 2 1.9 1.5 3.3l-11.6 32.6c-.5 1.3-1.9 2-3.3 1.5m94.1 250.4c-15.5 3.7-26.3 4.8-34.2 4.7-2.8.4-5.1-1-5.6-4.3l-2.9-25.5c.1-2.6.9-4.8 4.1-4.9 7.6.1 16.7-.7 19.5-1.6 4.2-1.4 5.3-.9 5.8-.2 10.3 13.8 13.4 25.6 13.4 31.1 0 .3-.1.5-.1.7"
      ></path>
      <path
        fill="#C62033"
        d="M306.7 290.8L308.2 290.8 308.2 295.1 309.2 295.1 309.2 290.8 310.8 290.8 310.8 290 306.7 290z"
      ></path>
      <path
        fill="#C62033"
        d="M314.6 293.6L313.2 290 311.8 290 311.8 295.1 312.7 295.1 312.7 291.1 312.8 291.1 314.3 295.1 315 295.1 316.5 291.1 316.5 291.1 316.5 295.1 317.4 295.1 317.4 290 316 290z"
      ></path>
    </svg>
  );
}

export default SoxIcon;
