import React from 'react';

import { ErrorWrapper } from '../AlertWrapper';
import { Typography } from '@hulu/react-style-components';
import CommunityCard from '@hulu-react-style-components/community-card';
import config from '@hulu/env-config';

export const AnnouncementBanner = () => {
  if (config.REACT_APP_DOMAIN === 'dpegrid.com') {
    return (
      <div class="banner-container">
        <CommunityCard style={{ paddingLeft: 0 }}>
          <ErrorWrapper>
            <Typography variant="body1">
              <Typography variant="h5">AWS Console Login on DPEGRID is deprecated</Typography>
              <br />
              Support and feature updates for this page are limited. For future cloud login needs and access to the
              latest features please use{' '}
              <a href="https://cloud.twdcgrid.com/" target="_blank" rel="noopener noreferrer">
                cloud.twdcgrid.com
              </a>
              <br />
            </Typography>
          </ErrorWrapper>
        </CommunityCard>
      </div>
    );
  }
  return <></>;
};

export default AnnouncementBanner;
