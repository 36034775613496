import React from 'react';

import Login from './Login/Login';
import CloudNavBar from '../../components/NavBar';

export default function Cloud() {
  return (
    <body>
      <CloudNavBar />
      <Login />
    </body>
  );
}
