import React from 'react';
import styled from 'styled-components';
import { spacers, colors } from '@hulu/design-tokens';

export const KnownAccountEnv = {
  PROD: 'PROD',
  SANDBOX: 'SANDBOX',
  'NON-PROD': 'NON-PROD',
};

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const Circle = styled.div`
  width: ${spacers.spacer02};
  height: ${spacers.spacer02};
  margin-right: ${spacers.spacer02};
  border-radius: 50%;
  background-color: ${({ backgroundColor }) => backgroundColor};
`;

const ENV_TO_LABEL = {
  [KnownAccountEnv['NON-PROD']]: { tagColor: '#9B1AE6', label: KnownAccountEnv['NON-PROD'] },
  [KnownAccountEnv.SANDBOX]: { tagColor: '#FFAA00', label: KnownAccountEnv.SANDBOX },
  [KnownAccountEnv.PROD]: { tagColor: '#1A71E6', label: KnownAccountEnv.PROD },
  DEFAULT: { tagColor: colors.colorGray4, label: 'UNKNOWN' },
};

const AccountEnvField = ({ env }) => {
  const { tagColor, label } = ENV_TO_LABEL[env] || ENV_TO_LABEL.DEFAULT;
  return (
    <Container>
      <Circle backgroundColor={tagColor} />
      {label}
    </Container>
  );
};

export default AccountEnvField;
