import React, { Fragment } from 'react';
import { Main } from '@hulu/margaret-plugins';
import { createGlobalStyle } from 'styled-components';
import FlagProvider from './components/FlagProvider';
import Routes from './routes';
import './App.css';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family:
      Graphik,
      -apple-system,
      BlinkMacSystemFont,
      "Segoe UI",
      Roboto,
      Oxygen,
      Ubuntu,
      Cantarell,
      "Fira Sans",
      "Droid Sans",
      "Helvetica Neue",
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-display: fallback;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  account-number {
    background-color: #f0f1f5;
  }

  .CommunityModal-content {
    min-width: 500px;
  }
`;

export default function App() {
  return (
    <Fragment>
      <GlobalStyle />
      <FlagProvider>
        <div data-testid="app-root">
          <Main>{Routes}</Main>
        </div>
      </FlagProvider>
    </Fragment>
  );
}
