import { configureStore } from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';
import RolesListSlice from './slices/rolesListSlice';
import AccountsListSlice from './slices/accountsListSlice';
import AssumeSlice from './slices/assumeSlice';
import RoleTypeSlice from './slices/roleTypeSlice';

const logger = createLogger({
  diff: true,
  duration: true,
});

const middlewares = [];
if (process.env.NODE_ENV !== 'production') {
  middlewares.push(logger);
}

const store = configureStore({
  reducer: {
    RolesListSlice: RolesListSlice,
    AccountsListSlice: AccountsListSlice,
    AssumeSlice: AssumeSlice,
    RoleTypeSlice: RoleTypeSlice,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(middlewares),
  devTools: process.env.NODE_ENV !== 'production',
});

export default store;
