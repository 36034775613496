// due to the issue we can't use new jsx transform syntax https://github.com/storybookjs/storybook/issues/13421
import React from 'react';
import styled from 'styled-components';
import { colors } from '@hulu/design-tokens';
import { Typography } from '@hulu/react-style-components';
import pageNotFoundBackground from './assets/images/page-not-found.jpg';

const PageNotFoundContainer = styled.div`
  height: 100vh;
  background-image: url(${pageNotFoundBackground});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;

const PageNotFoundText = styled(Typography)`
  margin-top: 0;
  text-align: center;
  color: ${colors.colorWhite};
`;

const PageNotFound = () => (
  <PageNotFoundContainer>
    <PageNotFoundText variant="h1">Whoops! That page does not exist.</PageNotFoundText>
  </PageNotFoundContainer>
);

export default PageNotFound;
