import React, { Fragment } from 'react';
import { useLDClient, withLDProvider } from 'launchdarkly-react-client-sdk';
import config from '@hulu/env-config';
import { useHuluSSO } from '@hulu/react-sso';

/**
 * Provider sets the user id on LaunchDarkly
 */
function Provider({ children }) {
  const [auth] = useHuluSSO();
  const ldClient = useLDClient();
  if (config.NODE_ENV !== 'test' && auth !== null && ldClient) {
    ldClient.identify({
      key: auth.objectGUID || 'unknown',
      email: auth?.email,
    });
  }
  return <Fragment>{children}</Fragment>;
}

/**
 * FlagProvider initializes the LaunchDarkly client
 */
const FlagProvider = withLDProvider({
  clientSideID: config.REACT_APP_LAUNCHDARKLY_ID,
  options: {
    sendLDHeaders: false,
  },
})(Provider);

export default FlagProvider;
