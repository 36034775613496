import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { PENDING, SUCCEEDED, FAILED } from '../constants';
import axios from 'axios';
import config from '@hulu/env-config';

export const fetchRoleTypesThunk = createAsyncThunk('roles/types', async (_, { rejectWithValue }) => {
  try {
    const response = await axios.get(`${config.REACT_APP_EXAMPLE_API_URL}/roles/types`);
    return response.data;
  } catch (e) {
    return rejectWithValue(e.response);
  }
});

const RoleTypeSlice = createSlice({
  name: 'roleTypes',
  initialState: {
    roleTypes: [],
    RoleTypeSliceStatus: null,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRoleTypesThunk.pending, (state) => {
      state.RoleTypeSliceStatus = PENDING;
    });
    builder.addCase(fetchRoleTypesThunk.fulfilled, (state, action) => {
      state.RoleTypeSliceStatus = SUCCEEDED;
      state.roleTypes = action.payload.roleTypes;
    });
    builder.addCase(fetchRoleTypesThunk.rejected, (state) => {
      state.RoleTypeSliceStatus = FAILED;
    });
  },
});

export default RoleTypeSlice.reducer;
