import React, { Fragment, useEffect, useState } from 'react';
import { useSearchParams } from '@hulu/react-router-dom';
import IconButton from '@mui/material/IconButton';
import { Button } from '@hulu/react-style-components';
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { LinearProgress } from '@mui/material';
import CommunityCard from '@hulu-react-style-components/community-card';

import CloudNavBar from '../../components/NavBar';

export default function Cloud() {
  const [searchParams] = useSearchParams();
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    jiraIssueID: '',
    justification: '',
  });

  const getLoginURL = () => {
    const accountNumParam = `accountNum=${searchParams.get('accountNum')}`;
    const roleNameParam = `&roleName=${searchParams.get('roleName')}`;
    const serviceIDParam = searchParams.get('YPServiceID') ? `&YPServiceID=${searchParams.get('YPServiceID')}` : '';
    const url = `${window.location.origin}/api/roles/assume?${accountNumParam}${roleNameParam}${serviceIDParam}`;
    return url;
  };

  useEffect(() => {
    if (searchParams.get('sox') === 'true') {
      setOpen(true);
    } else {
      const url = getLoginURL();
      window.location.href = url;
    }
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <body>
      <CloudNavBar />
      <CommunityCard style={{ margin: '1rem auto', width: '90%', alignSelf: 'center' }}>
        <h3>Logging in to AWS...</h3>
        <div style={{ color: 'orange' }}>
          <LinearProgress color="inherit" />
        </div>
        <Dialog
          open={open}
          PaperProps={{
            component: 'form',
            onSubmit: (e) => {
              e.preventDefault();
              const url = getLoginURL();
              const soxParams = `&jiraIssueID=${formData.jiraIssueID}&justification=${formData.justification}`;
              window.open(url + soxParams);
              handleClose();
            },
          }}
        >
          <DialogTitle>SOX Justification</DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
              <DialogContentText>Please enter a Jira issue ID and justification to assume SOX role</DialogContentText>
              <TextField
                name="jiraIssueID"
                id="jiraIssueID"
                label="Jira Issue ID"
                value={formData.jiraIssueID}
                onChange={handleInputChange}
                inputProps={{ 'data-testid': 'jiraIssueID' }}
              />
              <TextField
                name="justification"
                id="justification"
                label="Justification"
                value={formData.justification}
                onChange={handleInputChange}
                inputProps={{ 'data-testid': 'justification' }}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button type="submit" data-testid="submit">
              Log In
            </Button>
          </DialogActions>
        </Dialog>
      </CommunityCard>
    </body>
  );
}
