import React, { Fragment, useEffect, useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Logout from '@mui/icons-material/Logout';
import { Button } from '@hulu/react-style-components';
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

function getLoginURL(rowData) {
  const url = `${window.location.origin}/api/roles/assume?accountNum=${rowData.accountNum}&roleName=${rowData.roleName}`;
  const sParam = rowData.roleName === 'ServiceAccess' ? `&YPServiceID=${rowData.serviceID}` : '';
  return url + sParam;
}

export default function LoginButton({ rowData }) {
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    jiraIssueID: '',
    justification: '',
  });
  const handleOpen = (event) => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <div style={{ display: 'flow' }}>
      <Button
        style={{ alignSelf: 'center', display: 'inline-block' }}
        key={rowData.roleArn}
        onClick={() => {
          if (!rowData.regulatoryComplianceRequired) {
            window.open(getLoginURL(rowData));
          } else {
            setOpen(true);
          }
        }}
      >
        Log In
      </Button>

      <Dialog
        open={open}
        PaperProps={{
          component: 'form',
          onSubmit: (e) => {
            e.preventDefault();
            const url = getLoginURL(rowData);
            const soxParams = `&jiraIssueID=${formData.jiraIssueID}&justification=${formData.justification}`;
            window.open(url + soxParams);
            handleClose();
          },
        }}
      >
        <DialogTitle>SOX Justification</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
            <DialogContentText>Please enter a Jira issue ID and justification to assume SOX role</DialogContentText>
            <TextField
              name="jiraIssueID"
              id="jiraIssueID"
              label="Jira Issue ID (required)"
              value={formData.jiraIssueID}
              onChange={handleInputChange}
              inputProps={{ 'data-testid': 'jiraIssueID' }}
            />
            <TextField
              name="justification"
              id="justification"
              label="Justification (required)"
              value={formData.justification}
              onChange={handleInputChange}
              inputProps={{ 'data-testid': 'justification' }}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button type="submit" data-testid="submit" disabled={!(formData.jiraIssueID && formData.justification)}>
            Log In
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
