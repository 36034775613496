import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from '@hulu/react-router-dom';
import CommunityCard from '@hulu-react-style-components/community-card';
import { PENDING, SUCCEEDED } from '../../../redux/constants';
import { fetchRolesThunk } from '../../../redux/slices/rolesListSlice';
import { fetchRoleTypesThunk } from '../../../redux/slices/roleTypeSlice';
import TableWithHeaders from '../../../components/TableWithHeaders';
import AccountEnvField from '../../../components/AccountEnvField';
import LoginButton from '../../../components/LoginButton/LoginButton';
import { LinearProgress } from '@mui/material';
import { Popover, Typography, IconButton, SvgIcon } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ContentCopy } from '@mui/icons-material';
import DocuBox from '../../../components/DocuBox';
import config from '@hulu/env-config';

import SoxIcon from './icons/sox.jsx';
import { Chip } from '@hulu/react-style-components';
import AnnouncementBanner from '../../../components/AnnouncementBanner';

export function filterRoles(roles, roleTypeFilter) {
  let rRoles = roles;

  if (roleTypeFilter && roleTypeFilter !== 'all_roles') {
    rRoles = rRoles.filter((x) => x.roleType.type === roleTypeFilter);
  }
  return rRoles;
}

export function parseRolesForTable(rawRoleList) {
  const res = [];
  for (const role of rawRoleList) {
    const row = {
      id: role.roleArn,
      accountName: role.account.fullName,
      accountShortName: role.account.shortName,
      accountEnv: role.account.environment,
      accountNum: role.account.awsNumber,
      roleName: role.roleName,
      roleArn: role.roleArn,
      roleType: role.roleType.type,
      serviceName: role.YPServiceName,
      serviceID: role.YPServiceID,
      sessionTags: role.sessionTags,
      regulatoryComplianceRequired: role.regulatoryComplianceRequired,
    };
    res.push(row);
  }
  return res;
}

export function getLoginURL(rowData, bookmark = false) {
  const base = bookmark ? `${window.location.origin}/bookmark?` : `${window.location.origin}/api/roles/assume?`;
  const params = `accountNum=${rowData.accountNum}&roleName=${rowData.roleName}`;
  const soxParam = rowData.regulatoryComplianceRequired ? '&sox=true' : '';
  const sParam = rowData.roleName === 'ServiceAccess' ? `&YPServiceID=${rowData.serviceID}` : '';

  const url = base + params + soxParam + sParam;
  return url;
}

export function Login() {
  const [filteredRoles, setFilteredRoles] = useState([]);
  const { roles, RolesListSliceStatus } = useSelector((state) => state.RolesListSlice);
  const [anchorEl, setAnchorEl] = React.useState(false);
  const [searchParams] = useSearchParams();

  const CustomCell = styled('div')(({ theme }) => ({
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  }));

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const dispatch = useDispatch();

  const accountEnvFilterList = searchParams.get('accountEnv') ? searchParams.get('accountEnv').split(',') : null;
  const roleTypeFilterList = searchParams.get('roleType') ? searchParams.get('roleType').split(',') : ['readonly'];
  const columns = [
    {
      field: 'accountName',
      title: 'Account Name',
      hidden: false,
      defaultFilter: searchParams.get('accountName'),
    },
    {
      field: 'accountShortName',
      title: 'Short Name',
      hidden: false,
      defaultFilter: searchParams.get('accountShortName'),
    },
    {
      field: 'accountEnv',
      title: 'Environment',
      hidden: false,
      filterOnItemSelect: true,
      defaultFilter: accountEnvFilterList,
      lookup: {
        PROD: 'PROD',
        'NON-PROD': 'NON-PROD',
        SANDBOX: 'SANDBOX',
      },
      render: (rowData) => <AccountEnvField env={rowData.accountEnv} />,
    },
    {
      field: 'accountNum',
      title: 'Account Number',
      hidden: false,
      defaultFilter: searchParams.get('accountNum'),
    },
    {
      field: 'serviceName',
      title: 'Service Name',
      hidden: false,
      defaultFilter: searchParams.get('serviceName'),
      customFilterAndSearch: (term, rowData) => {
        const lowerTerm = term.toLowerCase();
        const name = rowData.serviceName?.toLowerCase() || '';
        const id = rowData.serviceID?.toLowerCase() || '';
        return name.includes(lowerTerm) || id.includes(lowerTerm);
      },
      render: (rowData) => {
        if (!rowData.serviceName) {
          return <></>;
        }
        return (
          <>
            {rowData.serviceName}
            {rowData.regulatoryComplianceRequired && <SvgIcon fontSize="16px" component={SoxIcon} />}
            <Typography variant="caption" display="block">
              {rowData.serviceID}
            </Typography>
          </>
        );
      },
    },
    {
      field: 'roleName',
      title: 'Role Name',
      hidden: false,
      defaultFilter: searchParams.get('roleName'),
      render: (rowData) => {
        if (rowData.roleName === 'ServiceAccess') {
          return (
            <>
              {rowData.roleName}
              <Chip label="BETA" color="secondary" />
            </>
          );
        } else {
          return rowData.roleName;
        }
      },
    },
    {
      field: 'roleType',
      title: 'Role Type',
      hidden: false,
      filterOnItemSelect: true,
      defaultFilter: roleTypeFilterList,
      lookup: {
        readonly: 'readonly',
        service: 'service',
        other: 'other',
      },
    },
    {
      field: 'buttonLink',
      title: 'Action',
      filtering: false,
      hiddenByColumnsButton: true,
      render: (rowData) => (
        <CustomCell>
          <LoginButton rowData={rowData} />
          <IconButton
            variant="contained"
            onClick={(e) => {
              navigator.clipboard.writeText(getLoginURL(rowData, true));
              handleClick(e);
            }}
          >
            <ContentCopy />
          </IconButton>
        </CustomCell>
      ),
    },
  ];

  let [columnObject, setColumnObject] = useState(columns);
  const resetColFilters = () => {
    window.history.pushState(null, '', window.location.href.split('?')[0]);
    setColumnObject(columns);
    window.location.reload(false);
  };

  useEffect(() => {
    dispatch(fetchRolesThunk({}));
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchRoleTypesThunk({}));
  }, [dispatch]);

  return (
    <Fragment>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Typography sx={{ p: 2 }}>Copied URL to clipboard!</Typography>
      </Popover>
      <CommunityCard style={{ margin: '1rem auto', width: '90%', alignSelf: 'center' }}>
        {RolesListSliceStatus === PENDING && (
          <>
            <h3>Loading your roles...</h3> <LinearProgress />
          </>
        )}
        {RolesListSliceStatus === SUCCEEDED && roles.length > 0 && (
          <>
            <AnnouncementBanner />
            <TableWithHeaders
              title="role"
              columns={columnObject}
              data={parseRolesForTable(roles)}
              isLoading={RolesListSliceStatus === PENDING}
              resetColFilters={resetColFilters}
            />
          </>
        )}
        {roles.length === 0 && RolesListSliceStatus === SUCCEEDED && (
          <DocuBox
            title="No roles found"
            text="We didn't find any roles associated with your user account."
            linkText="See some common reasons for this issue here"
            url={config.REACT_APP_ERROR_URL}
          />
        )}
      </CommunityCard>
    </Fragment>
  );
}
export default Login;
