import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { PENDING, SUCCEEDED, FAILED } from '../constants';
import axios from 'axios';
import config from '@hulu/env-config';

export const fetchAssumeThunk = createAsyncThunk('roles/assume', async (body, { rejectWithValue }) => {
  try {
    const response = await axios.post(`${config.REACT_APP_EXAMPLE_API_URL}/roles/assume`, body);
    return response.data;
  } catch (e) {
    return rejectWithValue(e.response);
  }
});

const AssumeSlice = createSlice({
  name: 'assume',
  initialState: {
    assume: {},
    AssumeSliceStatus: null,
  },

  extraReducers: (builder) => {
    builder.addCase(fetchAssumeThunk.pending, (state) => {
      state.AssumeSliceStatus = PENDING;
    });
    builder.addCase(fetchAssumeThunk.fulfilled, (state, action) => {
      state.AssumeSliceStatus = SUCCEEDED;
      state.assume = action.payload;
    });
    builder.addCase(fetchAssumeThunk.rejected, (state, action) => {
      state.AssumeSliceStatus = FAILED;
    });
  },
});

export default AssumeSlice.reducer;
