import axios from 'axios';
import { useState, useEffect, useRef, useContext } from 'react';
import get from 'lodash.get';
import { AuthenticationContext } from './context';

export const LOADING_DATA = Symbol('LOADING_DATA');

const overrideInterval = false;

export function useHuluSSO({ checkInterval = 30e3, baseUrl = window.location.origin } = {}) {
  const [sso, setSSO] = useState(LOADING_DATA);
  const [userData, setUserData] = useState(null);
  // we need useRef here to avoid using it as effect dependency
  const intervalId = useRef(-1);
  useEffect(() => {
    const verifySSO = async () => {
      const current_valid_till = get(sso, 'valid_till', '');
      try {
        const response = await axios.get(`${baseUrl}/api/authenticate`, { withCredentials: true });
        const valid_till = get(response, 'data.valid_till');
        const auth_data = get(response, 'data.user_data');
        setUserData(auth_data);
        /*
         * Here we're updating the session data based on the session TTL
         * this should avoid rerenders when no changes are made
         * i.e. when session doesn't change, but we're getting a new
         * object every time [with the same values].
         */
        if (valid_till !== current_valid_till) {
          setSSO(response.data);
        }
      } catch (error) {
        clearInterval(intervalId.current);
        setSSO({ error });
      }
    };

    // check sso data on first load
    if (sso === LOADING_DATA) {
      verifySSO();
    }

    // set interval only on successful sso auth
    if (!sso.error) {
      const id = setInterval(verifySSO, overrideInterval || checkInterval);
      intervalId.current = id;
    }

    return () => {
      clearInterval(intervalId.current);
    };
  }, [baseUrl, checkInterval, sso]);

  return [sso, userData];
}

export const useAuthentication = () => {
  const ssoData = useContext(AuthenticationContext);
  return ssoData;
};
