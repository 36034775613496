import React, { useState } from 'react';
import { useHuluSSO, LOADING_DATA } from './hooks';
import { redirect } from './redirect';

const defaultBaseUrl = '';

export const AuthenticationContext = React.createContext({
  auth: null,
  roles: [],
  hasRole: () => false,
  setRoles: () => {},
  userData: null,
});

const getUsersRoles = (roleMap, usersGroups) => {
  if (roleMap === null || !Array.isArray(usersGroups)) {
    return [];
  }
  let ret = [];
  for (const [role, groups] of Object.entries(roleMap)) {
    if (groups.some((group) => usersGroups.includes(group))) {
      ret.push(role);
    }
  }
  return ret;
};

// Authentication context provider, internally we use our hook and redirect on invalid session
export const Authentication = ({
  children,
  checkInterval,
  render,
  redirectOnError = true,
  baseUrl = defaultBaseUrl,
}) => {
  const [auth, userData] = useHuluSSO({ checkInterval, baseUrl });
  const [roles, setRoles] = useState(null);

  const usersRoles = getUsersRoles(roles, auth.groups);
  const hasRole = (toCheck) => {
    if (usersRoles.length === 0) {
      return false;
    }
    if (!Array.isArray(toCheck)) {
      toCheck = [toCheck];
    }
    return toCheck.some((role) => usersRoles.includes(role));
  };
  // redirect to the login page if redirect is required and auth error is present
  if (redirectOnError && auth.error) {
    redirect();
    // Fallback info for transition between current page and login page
    return (
      <div style={{ textAlign: 'center' }}>
        <p>Authentication check failed. You will be redirected to the login page soon.</p>
        <button onClick={redirect}>Redirect Now</button>
      </div>
    );
  }
  return (
    <AuthenticationContext.Provider value={{ auth, hasRole, roles: usersRoles, setRoles, userData }}>
      {/* render children when data is loaded */}
      {auth !== LOADING_DATA && !render && children}
      {/* use render prop and pass auth data to it */}
      {auth !== LOADING_DATA && render && render(auth, userData)}
    </AuthenticationContext.Provider>
  );
};
