import MTable from '@material-table/core';
import { useState } from 'react';
import { useSearchParams } from '@hulu/react-router-dom';
import * as tableIcons from '@mui/icons-material';

function debounce(func, timeout = 500) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}

export default function TableWithHeaders(props) {
  const [searchParams, setSearchParams] = useSearchParams();
  const defaultOptions = {
    columnsButton: false,
    emptyRowsWhenPaging: false,
    headerStyle: { fontFamily: 'inherit', borderBottom: '2px solid black' },
    tableLayout: 'auto',
    pageSize: localStorage.getItem('rowsPerPage') ? localStorage.getItem('rowsPerPage') : 10,
    filtering: true,
    pageSizeOptions: [10, 50, 100],
    rowStyle: { height: '100px' },
    search: true,
    searchDebounceDelay: 0.15,
    searchText: searchParams.get('omniSearch'),
    showTitle: false,
    searchFieldAlignment: 'left',
    idSynonym: props.title + '-table',
  };

  function ColumnHidden(column) {
    if (localStorage.getItem(column.field) === null && !!column.hidden) {
      return true;
    } else {
      return localStorage.getItem(column.field) === 'true';
    }
  }

  const selectedColumns = props.columns.map((column) => {
    column.hidden = ColumnHidden(column);
    return column;
  })
    ? props.columns
    : [];

  return (
    <div>
      <MTable
        columns={selectedColumns}
        data={!!props.data ? props.data : []}
        title={!!props.title ? props.title : ''}
        options={!!props.options ? props.options : defaultOptions}
        onChangeColumnHidden={(e) => {
          localStorage.setItem(e.field, e.hidden);
        }}
        onRowsPerPageChange={(e) => {
          localStorage.setItem('rowsPerPage', e);
        }}
        onSearchChange={(e) => {
          if (e === '') {
            searchParams.delete('omniSearch');
          } else {
            searchParams.set('omniSearch', e);
          }
          debounce(setSearchParams(searchParams));
        }}
        onFilterChange={(filters) => {
          // first delete all column filter params
          props.columns.forEach((c) => searchParams.delete(c.field));
          // now add the ones that exist back in
          filters.forEach((f) => {
            // account for empty arrays
            if (!(Array.isArray(f.value) && !f.value.length)) {
              searchParams.set(f.column.field, f.value);
            }
          });
          debounce(setSearchParams(searchParams));
        }}
        actions={[
          {
            icon: tableIcons.RestartAltSharp,
            tooltip: 'Reset column filters',
            isFreeAction: true,
            onClick: (event) => {
              props.resetColFilters();
            },
          },
        ]}
      />
    </div>
  );
}
