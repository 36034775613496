import { Fragment, useContext } from 'react';
import { AuthenticationContext } from '../../../components/Authentication';
import CloudNavBar from '../../../components/NavBar';
import { Card, Grid, Paper } from '@mui/material';

export default function UserDetails() {
  const ssoData = useContext(AuthenticationContext);

  function convertEpochToSpecificTimezone(timeEpoch) {
    const d = new Date(0);
    d.setUTCSeconds(timeEpoch);
    return d.toLocaleString();
  }

  const UserDetailsGridItem = (detail) => (
    <>
      <Grid item xs={3}>
        <b>{detail.title}</b>:
        <br />
        <br />
        <Paper sx={{ backgroundColor: '#fff', padding: '5px' }}>{detail.data}</Paper>
      </Grid>
    </>
  );

  const groupList = ssoData.userData.groups.map((group) => <li key={group}>{group}</li>);

  return (
    <Fragment>
      {CloudNavBar(ssoData)}
      <Card style={{ margin: '1rem auto', width: '90%', alignSelf: 'center' }}>
        <Grid container columnSpacing={3} justifyContent="center" style={{ margin: '1rem auto' }}>
          <UserDetailsGridItem title={'Full Name'} data={ssoData.userData.properName} />
          <UserDetailsGridItem title={'User Name'} data={ssoData.userData.userName} />
          <UserDetailsGridItem title={'Email'} data={ssoData.userData.email} />
        </Grid>
      </Card>
      <Card style={{ margin: '1rem auto', width: '90%', alignSelf: 'center' }}>
        <Grid container columnSpacing={3} justifyContent="center" style={{ margin: '1rem auto' }}>
          <UserDetailsGridItem title={'Groups'} data={groupList} />
          <UserDetailsGridItem
            title={'Session Expiration'}
            data={convertEpochToSpecificTimezone(ssoData.userData.sessionExpiration)}
          />
          <UserDetailsGridItem title={'Hulu AD Object GUID '} data={ssoData.userData.huluADObject_Guid} />
        </Grid>
      </Card>
    </Fragment>
  );
}
