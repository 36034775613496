import deetLogo from '../../assets/deet-logo-white.svg';
import { Button, NavBar, Navigation } from '@hulu/react-style-components';
import { Logo } from '../Logo';
import React, { useContext } from 'react';
import config from '@hulu/env-config';
import UserIconMenu from '../AccountDetails/UserIconMenu';
import { useLocation } from '@hulu/react-router-dom';
import { AuthenticationContext } from '../Authentication';

export default function CloudNavBar(props, roleTypeFilter) {
  const location = useLocation();
  const ssoData = useContext(AuthenticationContext);
  const userGroups = ssoData.userData.groups;
  const EmptyComponent = () => {
    return <></>;
  };
  const defaultNavBarContents = [
    <div></div>,
    <a href={config.REACT_APP_DOCS_URL}>
      <Button icon="IconBook">Wiki</Button>
    </a>,
    UserIconMenu(ssoData.userData),
  ];

  const betaTesterNavBarContents = [
    <div></div>,
    <a href="https://confluence.disney.com/x/vZEyUw">
      <Button variant="outlined" icon="IconAlertWarningOutlined">
        Service Role Beta Live
      </Button>
    </a>,
    <a href={config.REACT_APP_DOCS_URL}>
      <Button icon="IconBook">Wiki</Button>
    </a>,
    UserIconMenu(ssoData.userData),
  ];

  const navbarContents = userGroups.includes('ProductionPlatformFoundationsTeam')
    ? betaTesterNavBarContents
    : defaultNavBarContents;

  return (
    <nav>
      <NavBar
        logo={<Logo src={deetLogo} data-testid="nav-bar-logo" />}
        appName="AWS Console Login"
        leftChildren={EmptyComponent}
        rightChildren={navbarContents}
      />
    </nav>
  );
}
