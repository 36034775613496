import styled from 'styled-components';
import { colors } from '@hulu/design-tokens';

const BaseWrapper = styled.div`
  box-sizing: boarder-box;
  margin-top: 20px;
  padding: 10px;
  border-style: solid;
  border-width: 2px;
  border-radius: 5px;
  line-height: 150%;
`;

export const SuccessWrapper = styled(BaseWrapper)`
  background-color: ${colors.colorGreen05};
  border-color: ${colors.colorGreen20};
`;

export const InfoWrapper = styled(BaseWrapper)`
  background-color: ${colors.colorBlue05};
  border-color: ${colors.colorBlue20};
`;

export const WarningWrapper = styled(BaseWrapper)`
  background-color: ${colors.colorYellow05};
  border-color: ${colors.colorYellow20};
`;

export const ErrorWrapper = styled(BaseWrapper)`
  background-color: ${colors.colorRed05};
  border-color: ${colors.colorRed20};
`;

export const AdminWrapper = styled(BaseWrapper)`
  background-color: ${colors.colorGray03};
  border-color: ${colors.colorGray20};
`;
